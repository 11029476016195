import { Button, Image, List, Radio, RadioChangeEvent, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useCart } from '../../hooks/use-cart';
import { useProducts } from '../../hooks/use-products';
import CartIcon from '../../icons/cart';
import { Product, ProductVariant } from '../../types/Product';
import { formatPrice } from '../../utils/format-price';
import { withProductVariantDetail } from '../../utils/productUtil';
import Breadcrumb from '../collection/CollectionBreadcrumb';
import Section from '../Section';
import SearchEngineOptimization from '../utility/seo';
import RelatedProduct from './RelatedProduct';

const { Text, Title } = Typography;

const ProductDetailPage = (props) => {
  const { handle } = props;
  const {
    actions: { addItem },
  } = useCart();
  const [product, setProduct] = useState<Product | undefined>();
  const {
    actions: { getProduct },
  } = useProducts();

  const [previewIndex, setPreviewIndex] = useState<number | undefined>();
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
  const [loading, setLoading] = useState(false);

  let imageUrls: string[] = [];
  if (product?.thumbnail) {
    imageUrls.push(product?.thumbnail);
  }
  if (product?.images) {
    imageUrls = imageUrls.concat(product?.images.map((i) => i.url));
  }

  useEffect(() => {
    const fetchProduct = async () => {
      const res = await getProduct(handle);
      setProduct(res);
      setSelectedVariant(res?.variants[0]);
    };
    fetchProduct();
  }, [handle]);

  const selectVariant = (optionId: string) => (e: RadioChangeEvent) => {
    const value = e.target.value;
    const otherOption = selectedVariant?.options.filter(
      (option) => option.option_id !== optionId
    );
    const selectVariant = product?.variants.find(
      (variant) =>
        variant.options.some(
          (option) => option.option_id == optionId && option.value == value
        ) &&
        otherOption?.every((option) =>
          variant.options.find((i) => i.value === option.value)
        )
    );
    setSelectedVariant(selectVariant);
  };

  const selectedVariantDetail = selectedVariant
    ? withProductVariantDetail(selectedVariant, product?.options)
    : undefined;

  const options =
    product?.variants.reduce<{ [key: string]: string[] }>((acc, cur) => {
      cur.options.forEach((option) => {
        if (!acc[option.option_id]) acc[option.option_id] = [];
        if (!acc[option.option_id].includes(option.value)) {
          acc[option.option_id].push(option.value);
        }
      });
      return acc;
    }, {}) || {};

  const addToCart = async () => {
    setLoading(true);
    try {
      await addItem({
        variant_id: selectedVariant?.id,
        quantity: 1,
        metadata:
          product!.variants.length > 1
            ? {
                attributes: selectedVariantDetail?.attributes,
              }
            : undefined,
      });
    } catch (_) {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <SearchEngineOptimization title={product?.title || 'สินค้า'} />
      <Breadcrumb
        type="product"
        collection={product?.collection}
        product={product}
      />
      <Section className="py-6">
        <div className="block md:flex">
          <div className="flex-1">
            <div className="w-full bg-neutral-1 p-[2px] rounded-[6px] shadow-small border border-light-divider">
              <div className="w-full pt-[75%] relative rounded-[6px] overflow-hidden">
                <div className="absolute left-0 top-0 w-full h-full">
                  <Image
                    src={imageUrls[0]}
                    width="100%"
                    height="100%"
                    className="object-cover"
                    preview={{ visible: false }}
                    onClick={() => setPreviewIndex(0)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 grid gap-4 grid-cols-4 w-full">
              {imageUrls.slice(1).map((imageUrl, index) => (
                <div
                  onClick={() => setPreviewIndex(index + 1)}
                  className={`cursor-pointer p-[2px] rounded-[6px] bg-neutral-1 shadow-small border transition-colors hover:border-primary-6`}
                >
                  <div
                    className="w-full pt-[75%] bg-cover bg-center"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex-[2] overflow-hidden mt-6 md:mt-0 md:ml-6 bg-neutral-1 rounded-[6px] shadow-small border border-light-divider p-6">
            <Title className="!text-light-title !mb-4" level={3}>
              {product?.title}
            </Title>
            <div className="inline-block mr-8">
              <Text className="block !font-medium !text-light-secondary mb-2">
                ราคา
              </Text>
              <Text className="!text-2xl !font-semibold !text-light-title">
                ฿{' '}
                {selectedVariantDetail?.price
                  ? formatPrice(selectedVariantDetail?.price)
                  : '-'}
              </Text>
            </div>
            <div className="inline-block">
              <Text className="block !font-medium !text-light-secondary mb-2">
                รหัสสินค้า
              </Text>
              <Text className="!text-2xl !font-semibold !text-light-title">
                {selectedVariantDetail?.sku || '-'}
              </Text>
            </div>
            {product && product.variants.length > 1 && (
              <List
                className="!mt-4"
                dataSource={Object.entries(options)}
                renderItem={([key, values]) => {
                  const option = product?.options.find((i) => i.id == key);
                  if (!option) return null;
                  return (
                    <div>
                      <Text className="block !font-medium !text-light-secondary !mt-2 !mb-2">
                        {option.title}
                      </Text>
                      <Radio.Group
                        onChange={selectVariant(option.id)}
                        value={
                          selectedVariant?.options.find(
                            (i) => i.option_id === option.id
                          )?.value
                        }
                      >
                        {values.map((value) => {
                          return (
                            <Radio.Button value={value}>{value}</Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  );
                }}
              />
            )}
            <Button
              onClick={addToCart}
              loading={loading}
              icon={<CartIcon />}
              className="!flex !mt-6 !px-6"
              type="primary"
              size="large"
            >
              <Text className="ml-2 !text-neutral-1">เพิ่มลงตะกร้า</Text>
            </Button>
            <pre className="mt-6 !text-light-primary whitespace-pre-wrap">
              {product?.description}
            </pre>
          </div>
        </div>
        <RelatedProduct product={product} />
      </Section>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            current: previewIndex,
            visible: previewIndex != null,
            onVisibleChange: (visible) =>
              setPreviewIndex(visible ? previewIndex : undefined),
          }}
        >
          {imageUrls.map((imageUrl) => (
            <Image src={imageUrl} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default ProductDetailPage;
