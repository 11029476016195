import MainLayout from '../../components/layout/MainLayout';
import React from 'react';
import { Router } from '@reach/router';
import ProductDetailPage from '../../components/products/ProductDetailPage';

const ProductPage = () => {
  return (
    <MainLayout>
      <Router basepath="/products">
        <ProductDetailPage path="/:handle" />
      </Router>
    </MainLayout>
  );
};

export default ProductPage;
