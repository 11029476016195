import { Button, Image, List, Radio, RadioChangeEvent, Typography } from 'antd';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useProducts } from '../../hooks/use-products';
import { Product } from '../../types/Product';
import { ProductCardList } from './ProductList';

const { Text } = Typography;

const RelatedProduct = ({ product }: { product?: Product }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const {
    actions: { listProduct },
  } = useProducts();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (product) {
      const fetchProducts = async () => {
        setLoading(true);
        try {
          const res = await listProduct({
            collectionIds: [product.collection.id],
            limit: 7,
          });
          setProducts(res.filter((i) => i.id !== product.id).slice(0, 6));
        } catch (_) {
        } finally {
          setLoading(false);
        }
      };
      fetchProducts();
    }
  }, [product]);

  if (!loading && products.length == 0) return null;
  return (
    <div className="w-full rounded-[6px] shadow-small border border-light-divider mt-6 overflow-hidden">
      <div className="px-6 py-3 bg-neutral-1 text-base border-b border-light-divider">
        สินค้าในประเภทเดียวกัน
      </div>
      <div className="p-6">
        <ProductCardList
          products={products}
          loading={loading}
          loadingSize={6}
        />
        <Link to={`/collections/${product?.collection.handle}`}>
          <Button className="!text-primary-6 mt-6">
            ดูสินค้าในประเภทเดียวกัน
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default RelatedProduct;
